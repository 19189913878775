.layout.map {
  display: flex;
  position: relative;
  width: 100%;
  background-color: #FFF;
  padding-top: 100px;
  flex-grow: 1;

  .map--text {
    background-color: #FFFFFF;
    position: absolute;
    top: 100px;
    z-index: 997;
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 15px;
    color: #585858;
    letter-spacing: 0;
    padding: 12px;
    &.auth_block{
      top: 112px;
    }
    @media (max-width: 768px) {
      top: 70px;
      font-size: 9px;
      color: #585858;
      &.auth_block{
        top: 70px;
      }
    }
  }

  @media (max-width: 768px) {
    padding-top: 68px;
  }

  #map {
    width: 100%;
    display: flex;
    flex: 1;
  }

  .map--controls, .map--footer {
    position: absolute;
    @media (max-width: 768px) {
      position: fixed;
    }
  }


  .map--footer {
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .container {
      position: relative;
      text-align: center;
      padding-bottom: 40px;
    }

    .rt-block {
      left: 0;
      position: absolute;
      bottom: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .btn.btn-blue {
        padding: 0;
        width: 40px;
        height: 40px;
        border-radius: 6px;
        margin-bottom: 6px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
    a {
      transition: all 0.3s ease 0s;
      opacity: 0.98;
      &:hover {
        opacity: 1;
      }
      svg {
        width: 72px;
        height: 72px;
      }
    }
  }
}

#map .domoy-map__zoom-controls, #map .domoy-map__search-controls {
  button {
    padding: 0;
    border-radius: 6px;
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & + button {
      margin-top: 8px;
    }
  }

  svg {
    width: 14px;
    height: 14px;
  }
  #search-button svg {
    width: 35px;
    height: 35px;
  }
}

#map .domoy-map__search-controls {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background: #FFF;
  border-radius: 6px;
  position: relative;


  #search-button {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 40002;
  }
  input {
    padding-right: 50px;
    border-radius: 6px;
    position: relative;
    z-index: 40001;
    box-shadow: 0 2px 16px 0 rgba(0,0,0,0.06);
  }
  *[class*="custom-scroll"] {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}

.mobile--menu {
  display: flex;
  flex-direction: column;
  bottom: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
  transform: translateX(120%);

  background-color: #FFF;
  padding: 91px 16px 24px;
  overflow-x:auto;
  opacity: 0;
  &.visible {
    z-index: 998;
    transform:translateX(0);
    opacity: 1;
  }

  .user-header__profile, .user-header__profile .user--profile {
    width: 100%;
  }
  .mobile--menu__content {
    flex: 1;
  }
  nav {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 26px;
    a {
      font-weight: 500;
      font-size: 14px;
      color: #1A1A1A;
      letter-spacing: 0;
      text-decoration: none;
      display: flex;
      flex-direction: row;
      width: 100%;
      & + a {
        margin-top: 16px;
      }
    }
  }
  .mobile--menu__footer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      text-decoration: none;
      padding: 0;
      svg {
        margin-right: 8px;
      }
    }
    .rt--project{
      background: #F2F2F2;
      border-radius: 5px;
      padding: 8px;
      text-decoration: none;
      font-size: 12px;
      color: #1A1A1A;
      letter-spacing: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-weight: 500;
      svg {
        width: 32px;
        height: 32px;
        margin-left: 8px;
      }
    }
  }
}

@media (max-width: 768px) {
  #map .domoy-map__search-controls button, #map .domoy-map__zoom-controls button {
    width: 32px;
    height: 32px;
  }
  #map .domoy-map__search-controls #search-button svg, #map .domoy-map__zoom-controls #search-button svg{
    width: 32px;
    height: 32px;
  }
  .layout.map .map--footer .container {
    padding-bottom: 24px;
    a svg {
      width: 32px;
      height: 32px;
    }
    .btn.btn-blue{
      width: 100%;
      max-width: 375px;
    }
  }

  #map .domoy-map__search-controls input{
    padding-right: 35px;
  }
}

.map--count {
  position: absolute;
  top: 190px;
  left: 24px;
  z-index: 997;

  .btn.btn-blue {
    padding: 12px;
    border-radius: 4px;
  }

  @media (max-width: 768px) {
    top: 132px;
    font-size: 9px;
    left: 12px;
    color: #585858;

    span {
      display: none;
    }
  }

}