.create-page {



  form {
    margin-top: 20px;
    max-width: 720px;
    width: 100%;
  }

  .create--options {
    display: flex;
    flex-direction: row;
  }

  .options-column {
    flex-direction: column;
    display: flex;
    margin-bottom: 50px;

    & + .options-column {
      margin-left: 120px;
    }
  }

  .column--title {
    font-weight: 600;
    font-size: 18px;
    color: #1A1A1A;
    letter-spacing: 0;
    margin-bottom: 24px;
  }

  .column-info {
    font-size: 14px;
    color: #767F85;
    letter-spacing: 0;
    margin-bottom: 16px;
  }

  .radio-input {
    display: flex;
    flex-direction: column;

    label + label {
      margin-top: 16px;
    }

    label {
      display: flex;
      flex-direction: row;
      align-items: center;

      .radio-label {
        position: relative;
        top: 2px;
      }
    }
  }

  .form--row {
    & + .form--row__geo {
      margin-top: 48px;
    }

    label {
      font-weight: 700;
      font-size: 18px;
      color: #1A1A1A;
      letter-spacing: 0;
      margin-bottom: 16px;
      display: inline-block;
    }

    .form--row__note {
      font-weight: 500;
      font-size: 14px;
      color: #767F85;
      letter-spacing: 0;
      margin-bottom: 16px;
    }

    textarea {
      width: 100%;
      resize: vertical;
      transition: border 0.3s ease 0s;
    }
  }

  .form--row__geo {
    button {
      width: 48px;
      height: 48px;
      border-radius: 9px;
      padding: 0;
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      align-items: center;
      margin-right: 16px;
    }

    .geo-point {
      display: flex;
      flex-direction: row;
      align-items: center;

      input {
        flex: 1;
      }
    }
  }

  .map--row {
    width: 100%;
    height: 405px;
    background-color: #e2e2e2;
    margin: 24px 0 32px 0;

    > div {
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 768px) {
    .form {
      max-width: 100%;
    }
    .create--options {
      flex-direction: column;

      .options-column + .options-column {
        margin-left: 0;
      }
    }
    .column--title {
      font-size: 14px;
      margin-bottom: 16px;
    }
    .radio-input label .radio + .radio-label {
      font-size: 13px;
      top: 0;
    }
    .column--info {
      font-size: 12px;
    }
  }
}

.counter__row {
  display: flex;
  flex-direction: row;
  align-items: center;

  & + .counter__row {
    margin-top: 17px;
  }

  .column--info {
    flex: 1;
    margin-right: 64px;

    .counter__title {
      font-weight: 500;
      font-size: 15px;
      color: #1A1A1A;
      letter-spacing: 0;
      line-height: 22px;
    }

    .counter__note {
      font-weight: 500;
      font-size: 14px;
      color: #767F85;
      letter-spacing: 0;
    }
  }

  .counter_actions {
    display: flex;
    flex-direction: row;
    align-items: center;

    button {
      width: 40px;
      height: 40px;
      border-radius: 6px;
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: center;
      justify-content: center;

      svg {
        position: relative;
        margin-right: 0;
      }
    }

    input {
      width: 40px;
      font-weight: 800;
      font-size: 13px;
      color: #1A1A1A;
      letter-spacing: 0;
      text-align: center;
    }
  }

  .counter__value {
    input {
      padding: 0;
      text-align: center;
      background-color: transparent;

      &:disabled {
        background-color: transparent;
      }
    }
  }
}


@media (max-width: 768px) {
  .counter__row .column--info .counter__title {
    font-size: 13px;
  }
  .counter__row .column--info .counter__note {
    font-size: 12px;
  }
  .counter__row .counter_actions button {
    width: 24px;
    height: 24px;
    border-radius: 4px;

    svg {
      width: 8.4px;
    }
  }

  .create-page .options-column {
    margin-bottom: 24px;
  }

  .counter__row .counter__value input {
    min-width: 80px;
  }
  .create-page .form--row label {
    font-size: 14px;
  }
  .create-page .form--row .form--row__note {
    font-size: 12px;
  }
  .create-page .form--row + .form--row__geo {
    margin-top: 24px;
  }
  .create-page .map--row > div {
    width: calc(100vw + 32px);
    margin-left: -16px;
  }
  .create-page .map--row{
    margin-bottom: 16px;
  }
  form > button[type="submit"] {
    margin: 0 auto 33px;
    display: block;
  }
}