.layout.addresses {
  .btn.btn-add-contact{
    display: flex;
    flex-direction: row;
    width: auto;
    align-items: center;
    text-decoration: none;
    padding: 0;
    margin-top: 24px;
    span {
      font-weight: 500;
      font-size: 15px;
      color: #1A1A1A;
      letter-spacing: 0;
      margin-left: 16px;
    }
  }
  .addresses__list {
    flex-direction: column;
    display: flex;
    max-width: 720px;
    width: 100%;
  }

  .address {
    flex-direction: column;
    display: flex;
    width: 100%;

    & + .address {
      margin-top: 16px;
    }

    .address--header {
      cursor: pointer;
      background-color: #EEF2E9;
      border-radius: 5px;
      padding: 16px 18px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .address--title {
        font-weight: 700;
        font-size: 18px;
        color: #1A1A1A;
        letter-spacing: 0;
      }
      button {
        width: 10px;
        height: 20px;
        padding: 0;
        margin: 0;
        svg {
          width: 10px;
          height: 20px;
        }
      }
    }
    .address--content{
      margin-top: 32px;
      display: none;
    }
    &.visible {
      .address--content {
        display: flex;
        flex-direction: column;
      }
      .address--header {
        button {
          transform: rotate(180deg);
        }
      }
    }
  }

  .address-content--block {
    & + .address-content--block {
      margin-top: 24px;
    }

    .block--title {
      font-weight: 700;
      font-size: 18px;
      color: #1A1A1A;
      letter-spacing: 0;
      margin-bottom: 16px;
    }
    .block--value {
      font-weight: 500;
      font-size: 15px;
      color: #1A1A1A;
      letter-spacing: 0;
      line-height: 22px;
    }
  }

  .people-count {
    display: flex;
    flex-direction: row;
    .count {
      display: flex;
      flex-direction: row;
      & + .count {
        margin-left: 16px;
      }
      .count-title {
        font-weight: 500;
        font-size: 15px;
        color: #1A1A1A;
        letter-spacing: 0;
        line-height: 22px;
      }
      .count-value {
        font-weight: 800;
        font-size: 15px;
        color: #1A1A1A;
        letter-spacing: 0;
        line-height: 22px;
        margin-left: 8px;
      }
    }
  }
  .address-content--buttons {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .btn {
      text-decoration: none;
      & + .btn {
        margin-left: 24px;
      }
    }
  }
}