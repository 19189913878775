.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: -1;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  opacity: 0;


  .modal--content {
    border-radius: 16px;
    background-color: #FFF;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.09);
    position: relative;
    padding: 40px 39px;
    transition: all 0.3s ease 0.1s;
    transform: translateY(120%);
    opacity: 0;
  }


  &.visible {
    z-index: 10000;
    opacity: 1;
    .modal--content {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .btn-close {
    position: absolute;
    top: 24px;
    right: 24px;
    width: 21px;
    height: 14px;
    padding: 0;
    svg {
      width: 16px;
      height: 14px;
    }
  }
  .modal--title {
    font-weight: 600;
    font-size: 26px;
    color: #1A1A1A;
    letter-spacing: 0;
    text-align: center;
  }
  .modal--note {
    margin-top: 18px;
    font-size: 15px;
    color: #767F85;
    letter-spacing: 0;
    text-align: center;
  }
}

.modal.registration {
  .modal--content {
    max-width: 518px;
    padding: 40px 48px;
  }
  .modal--socials {
    margin-top: 38px;
  }
}

.modal--socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > div {
    max-width: 288px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    & + div {
      margin-top: 16px;
    }
    &.social-vk {
      a {
        background: #457CB9;
        &:hover {
          background: lighten(#457CB9, 10%);
        }
      }
    }
    &.social-google {
      a {
        background: #F44336;
        &:hover {
          background: lighten(#F44336, 10%);
        }
      }
    }
  &.social-yandex {
      a {
        background: #FFCC00;
        color: #000000;
        &:hover {
          background: lighten(#FFCC00, 10%);
        }
      }
    }


    > a {
      display: flex;
      flex-direction: column;
      width: 100%;
      text-decoration: none;
      color: #FFF;
      text-align: center;
      font-weight: 700;
      font-size: 13px;
      letter-spacing: 0;
      padding: 15px 0;
      border-radius: 24px;
      transition: all 0.3s ease 0s;
      &:hover {
        transform: scale(0.98);
      }
    }
  }
}

@media (max-width: 768px) {
  .modal.registration .modal--content {
    max-width: calc(100vw - 24px);
    padding: 24px;
  }
  .modal .modal--title{
    font-size: 18px;
  }
  .modal .modal--note{
    font-size: 12px;
    margin-top: 10px;
  }
  .modal .modal--note{
    font-size: 12px;
  }

  .modal--socials>div{
    max-width: 100%;
    &.social--telegram{
      iframe {
        width: 100% !important;
        height: 44px !important;
      }
    }
  }

}