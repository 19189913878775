.page--content {
  max-width: 720px;
  margin-bottom: 60px;
  p {
    line-height: 1.3;
    @media (max-width: 768px) {
      font-size: 16px;
    }
    & + p {
      margin-top: 24px;

    }
  }

  ul {
    margin-top: 24px;
    padding-left: 40px;
    li {
      & + li {
        margin-top: 24px;
      }
    }
  }
}