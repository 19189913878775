.notification {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px;
  border-radius: 8px;
  margin: 0 auto;
  max-width: 800px;
  background-color: #e2e2e2;
  &.error {
    background: #F58787;
    color: #FFF;
  }
  &.success {
    background: #5CB400;
    color: #FFF;
  }
}


.notification + .notification {
  margin-top: 12px;
}