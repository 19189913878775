.layout.profile {
  .notification {
    width: 100%;
    text-align: left;
    margin-bottom: 12px;
    justify-content: flex-start;
    max-width: 100%;
  }
}

.profile-page {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-bottom: 40px;
  .profile--cover {
    width: 336px;
    margin-right: 48px;
    img {
      border-radius: 6px;
      width: 100%;
      height: auto;
    }
  }

  .form--row {
    display: flex;
    flex-direction: column;
    width: 100%;
    & + .form--row {
      margin-top: 24px;
    }
  }

  label {
    font-weight: 600;
    font-size: 18px;
    color: #1A1A1A;
    letter-spacing: 0;
    margin-bottom: 16px;
  }

  .form--contacts {
    & + .form--contacts {
      margin-top: 24px;
    }
    &.tel-block {
      margin-top: 32px;
      padding-top: 24px;
      label {
        & + .contacts--block {
          margin-top: 24px;
        }
      }
    }

    & + button {
      margin-top: 40px;
    }
  }

  .contacts--block {
    display: flex;
    flex-direction: row;
    & + .contacts--block {
      margin-top: 24px;
    }
    > div + div {
      margin-left: 48px;
    }
  }

  .contact-column {
    display: flex;
    flex-direction: column;
    input {
      min-width: 336px;
    }
    span {
      font-weight: 500;
      font-size: 14px;
      color: #767F85;
      letter-spacing: 0;
      margin-bottom: 16px;
    }
  }

  .btn-add-contact {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    margin-top: 24px;
    &:active {
      transform:scale(0.98);
    }
    span {
      margin-left: 16px;
      font-weight: 500;
      font-size: 15px;
      color: #1A1A1A;
      letter-spacing: 0;
    }
  }

  .del-contact {
    position: relative;
    top: 5px;
    padding: 0;
  }
}

@media (max-width: 768px) {
  .profile-page{
    flex-direction: column;

  }
  .profile-page .profile--cover{
    width: 100%;
    margin-right: 0;
    margin-bottom: 24px;
  }

  .profile-page {
    .btn.btn-blue {
      width: 100%;
    }
  }

  .profile-page .form--contacts.tel-block label+.contacts--block{
    flex-direction: column;
  }

  .profile-page .contacts--block>div+div{
    margin-left: 0;
    margin-top: 24px;
  }
}