.btn {
  appearance: none;
  border: 0;
  background: transparent;
  transition: all 0.3s ease 0s;
  display: inline-block;
  font-size: 13px;
  color: #1A1A1A;
  letter-spacing: 0;
  text-align: center;
  cursor: pointer;
  border-radius: 24px;
  padding: 12px 24px;
  outline: 0;
  svg {
    width: 19px;
    height: 19px;
  }
  &.btn-grey {
    background: #F2F2F2;
    text-decoration: none;

    display: flex;
    flex-direction: row;
    align-items: center;
    &:hover {
      background: darken(#F2F2F2, 10%);
    }
    svg {
      margin-right: 12px;
    }
    &:active {
      transform: scale(0.97);
    }
  }

  &.btn-blue {
    font-weight: 600;
    font-size: 13px;
    color: #FFFFFF;
    letter-spacing: 0;
    text-align: center;
    background-color: $mainColor;
    padding: 16px 30px;
    border-radius: 28px;
    &.btn-grey{
      color: #1A1A1A;
      background: #F2F2F2;
      &:hover {
        background: darken(#F2F2F2, 10%);
      }
    }
    &:hover {
      background-color: lighten($mainColor, 2%);
    }
    &:active {
      transform: scale(0.97);
    }
  }

  &.btn-load {
    position: relative;
    &:after {
      background-color: rgba(255, 255, 255, 0.5);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      content: "";
      display: block;
      width: 100%;
      height: 100%;
    }
    &:before {
      position: absolute;
      content: "";
      background-image: url("../../assets/loader.svg");
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 20px;
      z-index: 9;

    }
  }
  &.btn-telegram, &.btn-wh {
    background-color: #00AAF5;
    position: relative;
    color: #FFF;
    font-weight: 600;
    font-size: 13px;
    letter-spacing: 0;
    text-align: center;
    &:before {
      background-image: url("../../assets/telegram.svg");
      background-repeat: no-repeat;
      background-size: cover;
      content: "";
      display: inline-block;
      height: 30px;
      left: 20px;
      position: absolute;
      top: 5px;
      width: 30px;
    }
    &:hover {
      background-color: lighten(#00AAF5, 5%);
    }
  }

  &.btn-wh{
    background-color: #35D94F;
    &:hover {
      background-color: lighten(#35D94F, 5%);
    }
    &:before {
      background-image: url("../../assets/whatsapp.svg");
    }
  }
  &.btn-close-modal {

  }
}


#current_geo {
  padding: 0;
  border-radius: 6px;
  width: 40px;
  height: 40px;
  @media (max-width: 768px) {
    width: 32px;
    height: 32px;
  }
}