@import "vars";

* {padding: 0; margin: 0;box-sizing: border-box}
::selection{
  background: rgba(92, 180, 0, 0.9);
  color: #FFF;
}

html, body {
  width: 100%;
  height: -webkit-fill-available;
  min-height: 100vh;

  font-family: 'Inter', sans-serif;
  display: flex;
  a {
    color: $mainLinkColor;
  }
}

body.index_page {
 @media (max-width: 768px) {
   overflow: hidden;
 }
}


.layout {
  width: 100%;
  //overflow-x: hidden;
}

.container {
  width: 100%;
  max-width: $containerWidth;
  margin: 0 auto;
  @media (max-width: 1500px) {
    max-width: calc(100vw - 32px);
  }
}

.default--layout {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;

}

.layout.content {
  padding-top: 140px;
  @media (max-width: 768px) {
    padding-top: 87.39px;
  }
}

.user-header__profile {
  display: flex;
  flex-direction: row;
  align-items: center;
  a {
    text-decoration: none;
  }
  .btn-signout {
    width: 48px;
    height: 48px;
    background-color: #F2F2F2;
    border-radius: 5px;
    padding: 0;
    margin-left: 24px;
    &:hover {
      background-color: $mainLinkColor;
      svg {
        fill: #FFF;
      }
    }
    svg {
      position: relative;
      top: 2px;
      width: 16px;
      height: 16px;
      transition: all 0.3s ease 0s;
    }
  }
  .user--profile {
    background-color: #F2F2F2;
    border-radius: 5px;
    padding: 8px 11px;
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: all 0.3s ease 0s;
    .user--profile__info {
      display: flex;
      flex-direction: column;
    }
    img {
      width: auto;
      min-height: 56px;
      max-height: 56px;
      border-radius: 5px;
      margin-right: 16px;
    }
    .first_name,.last_name {
      font-weight: 700;
      font-size: 13px;
      color: #1A1A1A;
      letter-spacing: 0;
      line-height: 16px;
      transition: all 0.3s ease 0s;
    }
    .info {
      margin-top: 4px;
      font-size: 12px;
      color: #52555B;
      letter-spacing: 0;
      transition: all 0.3s ease 0s;
    }
  }
  a.user--profile{
    &:hover {
      background-color: $mainLinkColor;
      .first_name,.last_name, .info {
        color: #FFF;
      }
    }
  }
}

h1 {
  font-weight: 700;
  font-size: 26px;
  color: #1A1A1A;
  letter-spacing: 0;
  @media (max-width: 768px) {
    font-size: 18px;
  }
}

h2 {
  font-weight: 700;
  font-size: 20px;
  color: #1A1A1A;
  letter-spacing: 0;
  margin: 24px 0;
  @media (max-width: 768px) {
    font-size: 16px;
  }
}