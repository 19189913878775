.vmpopup-content {
  //background-color: #FFFFFF;
  //border-radius: 8px;
  width: 100%;
  height: 100%;
  overflow: scroll;
  //position: relative;
  padding: 16px;

  .user-header__profile {
    width: 100%;
     > div {
       width: 100%;
     }
  }
  .vmpopup_info {
    padding-top: 16px;
  }
  .popup--title {
    font-weight: 700;
    font-size: 15px;
    color: #1A1A1A;
    letter-spacing: 0;
    margin-bottom: 8px;
  }

  .persons-count {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 24px;
    .count-item {
      & + .count-item {
        margin-left: 20px;
      }
      .label {
        font-weight: 500;
        font-size: 14px;
        color: #1A1A1A;
        letter-spacing: 0;
        margin-right: 8px;
      }
      .value {
        font-weight: 800;
        font-size: 14px;
        color: #1A1A1A;
        letter-spacing: 0;
        text-align: center;
      }
    }
  }


  .popup--description {
    font-weight: 500;
    font-size: 14px;
    color: #1A1A1A;
    letter-spacing: 0;
    margin: 0 0 16px;
  }

  .btn-grey {
    font-weight: 600;
    text-align: center;
    font-size: 13px;
    color: #1A1A1A;
    letter-spacing: 0;
    justify-content: center;
    padding: 16px 32px;
  }

  .btns {
    .btn {
      width: 100%;
      & + .btn {
        margin-top: 16px;
      }
    }
  }
}


.vmhint {
  font-size: 13px;
  background: #FFF;
  border-radius: 6px;
  box-shadow: 0 2px 16px 0 rgba(0,0,0,0.10);
  font-weight: 500;
  display: inline-block;
  max-width: 220px;
  min-width: 220px;
  padding: 6px;
  width: 100%;
}

.vmmap-popup {
  position: absolute;
  box-shadow: 0 2px 16px 0 rgba(0,0,0,0.10);
  background-color: #FFF;
  border-radius: 8px;
  padding-bottom: 8px;
}

.vmmap-popup .arrow {
  position: absolute;
  display: block;
  width: 12px;
  height: 12px;
  background-size: cover;
  left: 50%;
  margin-left: -6px;
  bottom: 0;
  margin-bottom: -6px;
  background: #fff;
  transform: rotate(45deg);
}

.popup--contacts {
  margin-top: 16px;
  button, a.btn {
    text-decoration: none;
  }
  .popup-contact {
    & + .popup-contact {
      margin-top: 16px;
    }
    button {
      margin-bottom: 8px;
    }
    span {
      display: inline-block;
      width: 100%;
      text-align: center;
      font-weight: 500;
      font-size: 13px;
      color: #1A1A1A;
      letter-spacing: 0;
    }
  }
}


.vmpopup_modal__title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  span {
    font-weight: 700;
    font-size: 18px;
    color: #000000;
    letter-spacing: 0;
    line-height: 30px;
  }
  button {
    width: 13px;
    height: 13px;
    padding: 0;
    svg {
      width: 13px;
      height: 13px;
    }
  }
}

*[class*="balloon-pane"] {
  z-index: 99999 !important;
}

.map-cluster__icon {
  color: #FFF;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-weight: 600;
}