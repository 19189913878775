.input {
  font-size: 14px;
  color: #000000;
  letter-spacing: 0;
  padding: 12px 16px;
  appearance: none;
  border: 0;
  outline: 0;

  @media (max-width: 768px) {
    padding: 8.4px 12px;
    font-size: 13px;
  }

  &::placeholder {
    color: rgba(0, 0, 0, 0.7);
  }

  &.form-input {
    border: 1px solid #DFDEE3;
    border-radius: 4px;
    font-weight: 500;
    font-size: 15px;
    color: #1A1A1A;
    letter-spacing: 0;
    line-height: 22px;
    transition: all 0.3s ease 0s;
    &:focus {
      border: 1px solid #0948F0;
    }
  }
}

.radio {
  position: relative;
  width: 20px;
  height: 20px;
  display: inline-block;
  border: 1px solid #DFDEE3;
  border-radius: 20px;
  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    display: inline-block;
    -webkit-appearance: none;
    &:checked:after{
      width: 20px;
      height: 20px;
    }
    &:checked:before{
      width: 14px;
      height: 14px;
    }
    &:before {
      position: absolute;
      content: "";
      display: block;
      width: 0px;
      height: 0px;
      border-radius: 100%;
      background-color: #FFF;
      top: 50%;
      left: 50%;
      z-index: 4;
      transform: translate(-50%, -50%);
      transition: all 0.3s ease 0s;
    }
    &:after {
      position: absolute;
      content: "";
      display: block;
      width: 0px;
      height: 0px;
      border-radius: 100%;
      background-color: $mainColor;
      top: 50%;
      left: 50%;
      z-index: 2;
      transform: translate(-50%, -50%);
      transition: all 0.3s ease 0s;
    }
  }

  & + .radio-label {
    font-weight: 500;
    font-size: 15px;
    color: #1A1A1A;
    letter-spacing: 0;
    line-height: 22px;
    margin-left: 20px;
  }
}