.header {
  box-shadow: 0 5px 10px 0 rgba(0,0,0,0.09);
  padding: 20px 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: #FFF;

  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    .logo {
      svg {
        width: 308px;
        height: 56px;
      }
    }
    nav {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: center;
      a {
        font-weight: 500;
        font-size: 16px;
        color: #1A1A1A;
        letter-spacing: 0;
        text-align: center;
        text-decoration: none;
        transition: all 0.3s ease 0s;
        padding: 0 20px;
        &:hover, &.active {
          color: $mainLinkColor;
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: 15px 0;
    .container {
      .user-header__profile {
        display: none;
      }
      nav {
        display: none;
      }
    }
  }
}

.menu__mobile-trigger{
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
 .trigger__wrapper {
   position: relative;
   transform: rotate(0deg);
   transition: .5s ease-in-out;
   width: 16px;
   height: 14px;
   span {
     display: block;
     position: absolute;
     height: 2px;
     background-color: #1a1a1a;
     width: 16px;
     opacity: 1;
     left: 0;
     transform: rotate(0deg);
     transition: .25s ease-in-out;
     &:first-child{
       top: 0;
     }
     &:nth-child(2),&:nth-child(3){
       top: 6px;
     }
      &:nth-child(4){
        top: 12px;
      }
   }
   &.open {
     span {
       &:first-child{
         top: 7px;
         width: 0;
         left: 50%;
       }
       &:nth-child(2) {
         transform: rotate(45deg);
       }
       &:nth-child(3){
         transform: rotate(-45deg);
       }
       &:nth-child(4){
         top: 7px;
         width: 0;
         left: 50%;
       }
     }
   }
 }
}


@media (max-width: 768px) {
  .header .container {
    max-width: calc(100vw - 16px);
  }
  .menu__mobile-trigger{
    display: flex;
  }
  .header .container .logo svg{
    width: 176px;
    height: 32px;
  }
  .header .container{
    justify-content: space-between;
  }
  .header .header__profile {
    //display: none;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

